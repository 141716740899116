<template>
  <v-dialog v-model="dialog" max-width="900px" persistent>
    <v-card>
      <v-card-title>
        <span color="primary" class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" :valid="valid" style="padding:50px">
          <v-row>
            <v-col md="6">
              <v-text-field
                placeholder="العنوان"
                label="العنوان"
                v-model="postForm.title"
                :rules="[(v) => !!v || 'title is required']"
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                placeholder="title"
                label="title"
                v-model="postForm.entitle"
                :rules="[(v) => !!v || 'title is required']"
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                placeholder="رابط الفيديو"
                label="رابط الفيديو"
                v-model="postForm.video_link"
              />
            </v-col>

            <v-col md="6">
              <v-text-field
                placeholder="رابط البرنامج"
                label="رابط البرنامج"
                v-model="postForm.link"
              />
            </v-col>

            <v-col md="6">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="postForm.date"
                    label="تاريخ المقال"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="postForm.date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col md="4">
              <v-file-input
                v-model="image"
                chips
                label="صورة بارزة للمقال"
              ></v-file-input>
            </v-col>
            <v-col md="2">
              <div v-if="file" class="image-area" style="margin-bottom: 10px;">
                <img
                  :src="file.data.full_url"
                  v-if="file"
                  width="250px"
                  height="250px"
                />
                <!-- @click="openLink(img.data.full_url)" -->

                <a
                  class="remove-image"
                  @click="removeimg(file.id, 'file')"
                  href="#"
                  style="display: inline;"
                  >&#215;</a
                >
              </div>
            </v-col>

            <v-col md="4">
              <v-file-input
                v-model="attachment"
                chips
                label="المرفقات"
              ></v-file-input>
            </v-col>
            <!-- :rules="[(v) => !!v || 'please select an attachment']" -->

            <v-col md="2">
              <!-- <v-file-input
                v-model="image"
                chips
                label="صورة بارزة"
                :rules="[(v) => !!v || 'please select an image']"
              ></v-file-input> -->
              <div v-if="file1" class="image-area" style="margin-bottom: 10px;">
                <img
                  :src="file1.data.full_url"
                  v-if="file1"
                  width="250px"
                  height="250px"
                />
                <!-- @click="openLink(img.data.full_url)" -->

                <a
                  class="remove-image"
                  @click="removeimg(file1.id, 'file')"
                  href="#"
                  style="display: inline;"
                  >&#215;</a
                >
              </div>
            </v-col>
            <v-col md="4">
              <v-autocomplete
                v-model="postForm.status"
                :items="statuses"
                dense
                chips
                small-chips
                label="الحالة"
              ></v-autocomplete>
            </v-col>

            <!-- <v-col md="3">
              <v-autocomplete
                :loading="loadingCats"
                v-model="postCats"
                :items="categories"
                dense
                chips
                small-chips
                label="التصنيف"
                multiple
                item-text="title"
                item-value="id"
              ></v-autocomplete>
            </v-col> -->

            <v-col md="4">
              <v-autocomplete
                :loading="loadingTags"
                v-model="postTags"
                :items="tags"
                dense
                chips
                small-chips
                label="الوسم"
                multiple
                item-text="title"
                item-value="id"
              ></v-autocomplete>
            </v-col>
            <v-col md="4">
              <v-autocomplete
                :loading="loadingInstructors"
                v-model="postForm.author_id"
                :items="instructors"
                dense
                chips
                small-chips
                label="الكاتب"
                item-text="first_name"
                item-value="id"
              ></v-autocomplete>
            </v-col>

            <v-col md="12" style="padding-bottom:100px">
              <quill-editor
                placeholder="العنوان"
                v-model="postForm.body"
                ref="myQuillEditor"
                :options="editorOption"
                style="height:100%"
              />
            </v-col>
            <v-col md="12">
              <quill-editor
                placeholder="العنوان"
                v-model="postForm.enbody"
                ref="myQuillEditor1"
                :options="editorOption"
                style="height:100%"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="close">
          إلغاء
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          إرسال
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="formSubmitted">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!-- snackbar -->
    <v-snackbar v-model="snackbar" absolute top :multi-line="true">
      {{ error }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import ApiService from "@/services/api.service";
import { localStoragePrefix } from "@/config";
export default {
  props: ["formTitle", "record", "dialog", "items", "collection"],
  data() {
    return {
      valid: false,
      snackbar: false,
      snackbarMsg: null,
      snackbarColor: "",
      editorOption: {
        // some quill options
      },
      error: "",
      postForm: {
        id: null,
        title: null,
        body: "<h3>...أدخل النص هنا </h3>",
        entitle: null,
        enbody: "<h3>...insert text here</h3>",
        date: new Date().toISOString().substr(0, 10),
        status: null,
        author_id: null,
        video_link: null,
        featured_image: null,
        attachment: null,
        link: null,
      },
      attachment: null,
      loadingInstructors: false,
      instructors: [],
      postCats: [],
      postTags: [],
      postTags1: [],
      categories: [],
      tags: [],
      statuses: ["draft", "published"],
      loadingCats: false,
      loadingTags: false,
      formSubmitted: false,
      image: null,
      file: null,
      file1: null,
      menu2: false,
      userInfo: null,
    };
  },
  methods: {
    async removeimg(id, text) {
      const { data } = await ApiService.delete(`files/${id}`);
      switch (text) {
        case "file":
          this.file = null;
          this.courseForm["featured_image"] = null;
          break;
        case "file1":
          this.file1 = null;
          this.courseForm["attachment"] = null;
          break;
        case "file2":
          this.file2 = null;
          this.courseForm["certificate"] = null;
          break;
      }

      this.submit();
    },
    async getInstructors() {
      try {
        this.loadingInstructors = true;
        const { data } = await ApiService.get("users?fields=id,first_name");
        this.instructors = data.data;
        this.loadingInstructors = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getCats() {
      try {
        this.loadingCats = true;
        const { data } = await ApiService.get(
          "items/categories?fields=id,title"
        );
        this.categories = data.data;
        this.loadingCats = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getTags() {
      try {
        this.loadingTags = true;
        const { data } = await ApiService.get("items/tags?fields=id,title");
        this.tags = data.data;
        this.loadingTags = false;
      } catch (error) {
        console.log(error);
      }
    },
    async submit() {
      // console.log(this.background_color);
      try {
        if (this.$refs.form.validate()) {
          this.formSubmitted = true;
          let formData = new FormData();
          if (this.image) {
            formData.append("data", this.image);
            const fileReq = await ApiService.postFile(
              "files",
              formData,
              0,
              null
            );
            this.postForm["featured_image"] = fileReq.data.data.id;
          }
          if (this.attachment) {
            // let formData = new FormData();
            formData.append("data", this.attachment);
            const attachmentReq = await ApiService.postFile(
              "files",
              formData,
              0,
              null
            );
            this.postForm["attachment"] = attachmentReq.data.data.id;
          }
          let postReq;
          if (this.record) {
            postReq = await ApiService.update(
              `items/${this.collection}`,
              this.postForm.id,
              this.postForm
            );
            // console.log(postReq)
            for (let tag of this.postTags1) {
              this.deleteItemConfirm(tag);
            }
          } else {
            postReq = await ApiService.post(
              `items/${this.collection}`,
              this.postForm
            );
            // send cats req
            // console.log("categories", this.categories);
            // for (let cat of this.categories) {
            //   const catReq = await ApiService.post("items/posts_categories", {
            //     post_id: postReq.data.data.id,
            //     category_id: cat,
            //   });
            // }

            // send tags req
          }
          for (let tag of this.postTags) {
            const catReq = await ApiService.post("items/posts_tags", {
              post_id: postReq.data.data.id,
              tag_id: tag,
            });
          }
          this.formSubmitted = false;
          const msg = this.record
            ? "record updated successfully"
            : "record inserted successfully";
          this.$emit("formSubmitted", msg);
        } else {
          this.snackbar = true;
          this.error = "please fill all required fields";
        }
      } catch (error) {
        if (error.message !== "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        this.snackbar = true;
        console.log(error);
        this.formSubmitted = false;
      }
    },
    close() {
      this.$emit("closeDialog");
    },
    async deleteItemConfirm(id) {
      try {
        console.log(id);
        const data = await ApiService.delete(`items/posts_tags/${id}`);
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    },
  },

  async mounted() {
    if (this.record) {
      Object.keys(this.postForm).forEach((element) => {
        if (this.record[element]) {
          if (typeof this.record[element] == "object") {
            this.postForm[element] = this.record[element].id;
          } else {
            this.postForm[element] = this.record[element];
          }
        }
      });

      this.record.tag_id.forEach((element) => {
        this.postTags.push(element.tag_id);
        this.postTags1.push(element.id);
      });
      if (this.postForm.featured_image) {
        const { data } = await ApiService.getFile(
          "files",
          this.postForm.featured_image
        );
        this.file = data.data;
        console.log("file : ", this.file);
      }
      if (this.postForm.attachment) {
        const { data } = await ApiService.getFile(
          "files",
          this.postForm.attachment
        );
        this.file1 = data.data;
        console.log("file1 : ", this.file1);
      }
    }
    this.userInfo = JSON.parse(
      localStorage.getItem(localStoragePrefix + "userInfo")
    );
    this.getInstructors();
    this.getCats();
    this.getTags();
  },
};
</script>
<style lang="css">
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";
.image-area {
  position: relative;
  width: 50%;
  background: #333;
}
.image-area img {
  max-width: 100%;
  height: auto;
}
.remove-image {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
  top: -11px;
  right: -11px;
}
.remove-image:active {
  background: #e54e4e;
  top: -10px;
  right: -11px;
}
</style>
